import styled from 'styled-components';

export const Container = styled.form`
background: #FFFFFF;
margin: auto;
display: flex;
flex-direction: column;
width: 35%;
border-radius: 5px;
padding: 60px 70px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
line-height: 2.5;


div {
    display: flex;
    align-items: center;
}

label {
    font-size: 14.7px;
}

@media only screen and (max-width: 992px) {
    width: 90%;
    padding: 10px;
}
`

export const InputField = styled.div`
margin-bottom: 25px;
`

export const Input = styled.input`
background: #FFFFFF;
border: 1.13077px solid #EAEAEA;
border-radius: 5px;
padding: 10px 15px;
padding-left: 45px;
width: 100%;
outline: none;
`

export const Icon = styled.div`
background-color: #F2F4F7;
color: #999999;
position: absolute;
padding: 12px;
border-radius: 5px 0px 0px 5px;

img {
width: 14px
}`

export const Check = styled.div`
background-color: #F2F4F7;
border-radius: 0px 5px 5px 0px;
padding: 14px;
color: #999999;
position: absolute;
right: 70px;

img {
    width: 14px
}

@media only screen and (max-width: 992px) {
    right: 10px;
}
`

export const CheckboxLabel = styled.label`
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
color: #999999
`
export const Checkbox = styled.input`
border: 1px solid #999999;
border-radius: 2px;
padding: 0;
width: 16px;
height: 16px;
margin-right: 5px;
cursor: pointer
`
export const Header = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 20px;
gap: 20px;

h1 {
font-family: 'Roboto';
font-style: normal;
font-size: 28px;
text-align: center;
color: #555555;
}
`

export const Logo = styled.img`
width: 35px;
height: 40px
`

export const Btn = styled.button`
background-color: #0A5DFE;
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
border: none;
width: 100%;
color: white;
padding: 12px 16px;
font-size: 24px;
border-radius: 5px;
margin: 40px 0 20px 0;
cursor: pointer;
`

