import Authorization from './pages/Authorization/Authorization';
import './App.css';

function App() {
  return (
    <div className="App">
      <Authorization></Authorization>
    </div>
  );
}

export default App;
