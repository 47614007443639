import React, { useState } from 'react'
import { Container, Header, InputField, Logo, Input, Icon, Check, CheckboxLabel, Checkbox, Btn } from './Authorization.styled'
function Authorization() {
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    return (
        <Container>
            <Header>
                <Logo src="/Icons/Logo.png" alt="" />
                <h1>Log In!</h1>
            </Header>
            <label htmlFor="name" className="name">
                Username
            </label>
            <InputField>
                <Icon>
                    <img src="/Icons/account.png" alt="" />
                </Icon>
                <Input
                    id="name"
                    type="text"
                    placeholder="Username"
                />
            </InputField>

            <label htmlFor="pass" className="pass">
                Password
            </label>
            <div>
                <Icon>
                    <img src="/Icons/key.png" alt="" />
                </Icon>
                <Input
                    id="name"
                    type={passwordShown ? "text" : "password"}
                    placeholder="Password"
                />
                <Check onClick={togglePassword}>
                    <img src="/Icons/eye.png" alt="" />
                </Check>
            </div>
            <div>
                <Checkbox type="checkbox" name="checkbox" id="checkbox" />
                <CheckboxLabel htmlFor="checkbox">Remember me</CheckboxLabel>
            </div>
            <Btn>Sign In</Btn>
        </Container>
    )
}

export default Authorization
